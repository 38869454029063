import localForage from 'localforage';

class SettingsManager {
    constructor() {
        this.store = localForage.createInstance({
            name: "GamePlatform"
        });
    }

    async get(key, defaultValue = null) {
        return await this.store.getItem(key) || defaultValue;
    }

    async set(key, value) {
        await this.store.setItem(key, value);
    }
}

export const settingsManager = new SettingsManager();