<template>
  <div class="page p-4">
    <div class="flex flex-col gap-4">
      <div class="flex items-center gap-4">
        <img class="image-title" src="../assets/credits.svg" />
        <h1 class="title">{{ $t('about.title') }}</h1>
      </div>
      <p class="text-sm">{{ $t('about.content') }}</p>

    </div>
  </div>
</template>