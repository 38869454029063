<template>

  <div class="page h-full">
    <div class="flex h-full flex-col gap-4">
      <div class="flex justify-between items-center gap-4">
        <div class="flex items-center gap-4 px-4">
          <img class="image-title" src="../assets/bomb_mini.png" />
          <h1 class="title">Timebomb {{ $t('timebomb.game') }} #{{ roomId }}</h1>
        </div>
        <p v-if="room && me" class="p-4 text-lg font-bold">{{ me.username }}</p>
      </div>
      <div class="flex grow flex-col gap-2">
          <div class="flex flex-col md:flex-row grow gap-2">
            <div class="grow flex flex-col justify-between gap-2">
              <div class="grow justify-center md:justify-start bg-gray-800 p-2 rounded-md flex flex-wrap">
                <template v-if="!firstGame && room">

                  <div v-for="player in room.players" :key="player.socket" class="w-1/4 min-w-44 px-1">
                    <div class="w-full grid grid-cols-5 gap-2 rounded-md p-2"
                         :class="{'bg-gray-700': room.state === 'started', 'bg-green-700': room.state !== 'started' && player.role === 'Good', 'bg-red-700': room.state !== 'started' && player.role === 'Bad'}">
                      <p class="col-span-4 flex gap-2 items-center">
                        <span class="text-lg">{{ player.username }}</span>
                        <img class="h-5 invisible" src="">
                      </p>
                      <p class="col-span-1 flex justify-end items-center gap-2">
                        <span>{{ player.revealed }}</span>
                        <img class="h-5" src="../assets/defuse_mini.png">
                      </p>
                      <div class="col-span-5 flex gap-2 items-center">
                        <span class="text-base">{{ $t('timebomb.announces') }}</span>
                        <span id="wiresAnnounce-0">
                          {{ player.announcedWires === -1 ? '?' : player.announcedWires }}
                        </span>
                        <img class="h-5" src="../assets/defuse_mini.png">
                        <span id="bombAnnounce-0">
                          {{ player.announcedBomb === -1 ? '?' : player.announcedBomb }}
                        </span>
                        <img class="h-5" src="../assets/bomb_mini.png">
                      </div>

                      <img v-for="(fil, index) in player.fils" :key="index" @click="cutFil(player.username, index)"
                           class="rounded-md" :class="{ 'cursor-pointer': me.isCutter && player.username !== me.username, 'cursor-not-allowed': !me.isCutter ||  player.username === me.username }" :src="filMapping[fil]">
                    </div>
                  </div>

                </template>

              </div>
              <div v-if="!firstGame && room" class="bg-gray-800 p-2 rounded">
                <div class="grid grid-cols-10 gap-2 bg-gray-700 rounded-md p-2" id="gameMyBoard">
                  <div class="col-span-5 md:col-span-1 flex flex-col md:items-center gap-2">
                    <p id="roleName">{{ ucFirst($t('timebomb.' + me.role.toLowerCase())) }}</p>
                    <img id="role" class="h-16 md:h-auto w-fit md:w-auto rounded-md" :src="roleMapping[me.role]">
                  </div>
                  <div class="col-span-5 md:col-span-1 flex flex-col md:items-center gap-2">
                    <p v-if="me.isCutter" id="cutterName">{{ $t('timebomb.yourTurn') }}</p>
                    <img id="cutter" class="h-16 md:h-auto w-fit md:w-auto rounded-md" :class="{ 'invisible': !me.isCutter }" src="../assets/wire_cutter_mini.png">
                  </div>
                  <div class="col-span-10 md:col-span-3 flex flex-col gap-2" id="announces">
                    <p class="text-center" id="announcesTitle">{{ $t('timebomb.announces') }}</p>
                    <div class="grid grid-cols-4 gap-2">
                      <img class="h-16 rounded-md" src="../assets/defuse_mini.png" id="wiresTitle">
                      <div class="h-16 col-span-3 flex flex-wrap items-center gap-2">
                        <button @click="declareWires(0)" class="rounded-md bg-gray-600 p-4">0</button>
                        <button @click="declareWires(1)" class="rounded-md bg-gray-600 p-4">1</button>
                        <button @click="declareWires(2)" class="rounded-md bg-gray-600 p-4">2</button>
                        <button @click="declareWires(3)" class="rounded-md bg-gray-600 p-4">3</button>
                        <button @click="declareWires(4)" class="rounded-md bg-gray-600 p-4">4</button>
                        <button @click="declareWires(5)" class="rounded-md bg-gray-600 p-4">5</button>
                      </div>
                    </div>
                    <div class="grid grid-cols-4 gap-2">
                      <img class="h-16 rounded-md" src="../assets/bomb_mini.png" id="bombTitle">
                      <div class="h-16 col-span-3 flex flex-wrap items-center gap-2">
                        <button @click="declareBomb(0)" class="rounded-md bg-gray-600 p-4">0</button>
                        <button @click="declareBomb(1)" class="rounded-md bg-gray-600 p-4">1</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-10 md:col-span-5 grid grid-cols-5 gap-2">
                    <div v-for="(meFil, index) in me.fils" :key="index" class="flex flex-col items-center gap-2">
                      <p>{{ ucFirst($t('timebomb.' + handNameMapping[meFil].toLowerCase())) }}</p>
                      <img class="rounded-md" :src="handImgMapping[meFil]">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <form id="startGame">
                <div v-if="room && room.state !== 'started' && me && me.isHost && room.players.length >= 2" class="flex flex-col p-2 gap-2 bg-gray-800 rounded-md">
                  <button class="rounded-md bg-gray-600 p-2">{{ $t('timebomb.startGame') }}</button>
                </div>
              </form>
              <div v-if="!firstGame && room" class="flex flex-col gap-2 bg-gray-800 p-4 rounded-md min-w-max" id="gameInfo">
                <p class="text-lg text-left" id="gameInfoTitle">{{ $t('timebomb.gameInfoTitle') }}</p>
                <div class="flex items-center gap-2">
                  <span id="revealedTitle">{{ $t('timebomb.wiresRevealedTitle') }}</span>
                  <span id="totalRevealed">{{ room.revealed }}/{{ room.players.length }}</span>
                  <img class="h-5" src="../assets/defuse_mini.png">
                </div>
                <div class="flex items-center gap-2">
                  <span id="turnRevealedTitle">{{ $t('timebomb.cardsTurnTitle') }}</span>
                  <span id="turnRevealed">{{room.revealedDuringTurn}}/X</span>
                  <img class="h-5" src="../assets/back_mini.png">
                </div>
                <div class="flex items-center gap-2">
                  <span id="turnAnnouncedTitle">{{ $t('timebomb.wiresTurnTitle') }}</span>
                  <span id="turnAnnounced">{{ totalAnnounced }}</span>
                  <img class="h-5 w-5" src="../assets/speaker.svg">
                </div>
              </div>
              <div class="flex flex-col flex-1 justify-start gap-2">
                <div class="flex flex-col gap-2 bg-gray-800 p-4 rounded-md grow w-full min-w-max">
                  <p class="text-lg" id="playersTitle">{{ $t('timebomb.playersTitle') }}</p>
                  <div v-if="room && room.players" id="playersList" class="flex flex-col gap-1 bg-gray-800 rounded-md grow w-full">
                    <div v-for="player in room.players" :key="player.username" class="flex items-center justify-between flex-wrap gap-2">
                      <div class="flex items-center justify-start flex-wrap gap-2">
                        <img v-if="player.isHost" class="h-5 w-5" src="../assets/star.svg">
                        <img v-else class="h-5 w-5" src="../assets/arrow_right.svg">
                        <span class="cursor-pointer text-blue-400 hover:underline">{{ player.username }}</span></div>
                      <div class="flex items-center justify-end flex-wrap gap-2">
                        <span>{{ player.goodWins }}/{{ player.goodPlayed }}</span><img class="h-5" src="../assets/good_guy_1_mini.png">
                        <span>{{ player.badWins }}/{{ player.badPlayed }}</span><img class="h-5" src="../assets/bad_guy_1_mini.png">
                        <span>{{ player.wins }}/{{ player.played }}</span><img class="h-5" src="../assets/good_bad.png">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col justify-end gap-2 gap-2 bg-gray-800 p-2 rounded-md h-[200px]" style="flex: 1 1 auto;">
                <div class="w-full flex flex-col gap-2 overflow-hidden" style="flex: 1 1 auto;">
                  <div class="flex flex-col-reverse overflow-auto" style="flex: 1 1 auto;">
                    <div class="flex flex-col w-full" id="messages">
                    </div>
                  </div>
                </div>
                <form id="send-message-form" class="w-full flex flex-col gap-2 mb-0">
                  <input class="text-black w-full rounded-md p-2" :placeholder="$t('timebomb.writeInChat')" type="text" id="message">
                  <button class="rounded-md bg-gray-600 p-2" type="submit" id="sendTitle">{{ $t('timebomb.send') }}</button>
                </form>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filMapping: {
        1: require("../assets/back_mini.png"),
        2: require("../assets/back_mini.png"),
        3: require("../assets/back_mini.png"),
        11: require("../assets/neutral_mini.png"),
        12: require("../assets/defuse_mini.png"),
        13: require("../assets/bomb_mini.png")
      },
      roleMapping: {
        'Good': require('../assets/good_guy_1_mini.png'),
        'Bad': require('../assets/bad_guy_1_mini.png'),
      },
      handNameMapping: {
        1: 'Neutral',
        2: 'Wire',
        3: 'Bomb',
        11: 'Neutral',
        12: 'Wire',
        13: 'Bomb',
        // 11: 'Neutral (Revealed)',
        // 12: 'Wire (Revealed)',
        // 13: 'Bomb (Revealed)',
      },
      handImgMapping: {
        1: require("../assets/neutral_mini.png"),
        2: require("../assets/defuse_mini.png"),
        3: require("../assets/bomb_mini.png"),
        11: require("../assets/neutral_mini.png"),
        12: require("../assets/defuse_mini.png"),
        13: require("../assets/bomb_mini.png")
      },
    }
  },
  mounted() {
    const socket = this.$store.state.socket;

    document.getElementById('send-message-form').addEventListener('submit', (event) => {
      event.preventDefault();
      const messageInput = document.getElementById('message');
      const message = messageInput.value;
      if (message === '') return;
      const roomId = this.roomId;
      socket.emit('sendMessage', roomId, message);
      messageInput.value = '';
    });

    document.getElementById('startGame').addEventListener('submit', (event) => {
      event.preventDefault();
      if (this.$store.state.interfaceLocked) return;
      socket.emit('startGameServer', false);
    });

    if (this.$store.state.room) {
      console.log('la');
      return;
    }

    this.playSound("exit.wav");

    // Constants

    const messagesContainer = document.getElementById("messages");

    const COLOR_GREEN = 'text-green-500';
    const COLOR_ORANGE = 'text-orange-500';
    const COLOR_RED = 'text-red-500';
    const COLOR_PURPLE = 'text-purple-500';
    const COLOR_SKY = 'text-sky-500';

    socket.on('message', (userId, username, message) => {
      let messagesContainerLocal = document.getElementById("messages");
      const messageElement = document.createElement('div');
      if (username === 'Admin') {
        messageElement.classList.add(COLOR_PURPLE, 'font-bold');
      }
      messageElement.innerHTML = `<strong>${username}</strong>: ${message}`;
      messagesContainerLocal.appendChild(messageElement);
    });

    socket.on('updateGameV2', (room) => {
      if (room.state === 'started')
        this.$store.state.firstGame = false;
      this.$store.commit('updateGame', room);
    });

    socket.on('joinedV2', (playerName) => {
      const messageElement = document.createElement('div');
      messageElement.classList.add(COLOR_GREEN);
      messageElement.innerHTML = `<strong>${playerName}</strong>` + ' ' + this.$t('timebomb.joined');
      messagesContainer.appendChild(messageElement);
      this.createNotif(this.$t('timebomb.playerJoined'), playerName+' ' + this.$t('timebomb.joined'), 'info', '5000');
    });

    socket.on('startGameClient', () => {
      this.createNotif(this.$t('timebomb.gameStarted'),this.$t('timebomb.gameStarted'), 'success', '5000');
      const messageElement = document.createElement('div');
      messageElement.classList.add(COLOR_ORANGE);
      messageElement.innerHTML = this.$t('timebomb.gameStarted');
      messagesContainer.appendChild(messageElement);
      this.playSound("page.wav");
    });

    socket.on('cutFilClient', (room, cutter, target, code) => {
      if (this.$store.state.interfaceLocked) return;
      this.$store.commit('updateGame', room);
      const messageElement = document.createElement('div');
      switch (code) {
        case 3:
        case 4:
          this.createNotif(this.$t('timebomb.wireCut'), cutter + ' ' + this.$t('timebomb.cutNeutralIn') + ' ' + target + this.$t('timebomb.inHand'), 'neutral', '5000');
          messageElement.classList.add(COLOR_SKY);
          messageElement.innerHTML = cutter + ' ' + this.$t('timebomb.cutNeutralIn') + ' ' + target + this.$t('timebomb.inHand');
          break;
        case 1:
        case 5:
        case 6:
          this.createNotif(this.$t('timebomb.wireCut'), cutter + ' ' + this.$t('timebomb.cutWireIn') + ' ' + target + this.$t('timebomb.inHand'), 'wire', '5000');
          messageElement.classList.add(COLOR_GREEN);
          messageElement.innerHTML = cutter + ' ' + this.$t('timebomb.cutWireIn') + ' ' + target + this.$t('timebomb.inHand');
          break;
        case 2:
          this.createNotif(this.$t('timebomb.wireCut'), cutter + ' ' + this.$t('timebomb.madeExplode') + ' ' + target + this.$t('timebomb.inHand'), 'bomb', '5000');
          messageElement.classList.add(COLOR_RED);
          messageElement.innerHTML = cutter + ' ' + this.$t('timebomb.madeExplode') + ' ' + target + this.$t('timebomb.inHand');
          break;
        default:
          break;
      }
      messagesContainer.appendChild(messageElement);
      this.playSound("cut.wav");

    });

    socket.on('beforeNextTurn', () => {
      this.$store.state.interfaceLocked = true;
      let text = this.$t('timebomb.turnFinished');
      this.createNotif(text, text, 'info', '5000');
      const messageElement = document.createElement('div');
      messageElement.classList.add(COLOR_ORANGE);
      messageElement.innerHTML = text;
      messagesContainer.appendChild(messageElement);
    });

    socket.on('nextTurn', (room) => {
      this.$store.state.interfaceLocked = false;
      this.$store.commit('updateGame', room);

      let cutter = room.players.find(p => p.isCutter);

      let text = this.$t('timebomb.newTurnDot') + ' ' + cutter.username + ' ' + this.$t('timebomb.canCut');
      this.createNotif(this.$t('timebomb.newTurn'), text, 'info', '5000');
      const messageElement = document.createElement('div');
      messageElement.classList.add(COLOR_ORANGE);
      messageElement.innerHTML = text;
      messagesContainer.appendChild(messageElement);
      this.playSound("woosh.wav");
    });

    socket.on('winGame', (room) => {
      let pls = '';
      let pls2 = '';
      room.players.forEach(player => {
        if (player.role === "Good") {
          pls = pls + player.username + ', ';
        } else {
          pls2 = pls2 + player.username + ', ';
        }
      });
      pls = pls.slice(0, -2);
      pls2 = pls2.slice(0, -2);
      let text = this.$t('timebomb.goodWins') + '\n\n' + 'Good: ' + pls + '\n' + 'Bad: ' + pls2;
      this.createNotif(this.$t('timebomb.goodWins'), text, 'good', '60000');
      // setPopin(text, 60000, 'green', false); // TODO

      let messageElement = document.createElement('div');
      messageElement.classList.add(COLOR_ORANGE);
      messageElement.innerHTML = this.$t('timebomb.goodWins');
      messagesContainer.appendChild(messageElement);

      messageElement = document.createElement('div');
      messageElement.classList.add(COLOR_GREEN);
      messageElement.innerHTML = this.$t('timebomb.goodDoubleDot') + ' ' + pls;
      messagesContainer.appendChild(messageElement);

      messageElement = document.createElement('div');
      messageElement.classList.add(COLOR_RED);
      messageElement.innerHTML = this.$t('timebomb.badDoubleDot') + ' ' + pls2;
      messagesContainer.appendChild(messageElement);

      this.playSound("bell.wav");
    });

    socket.on('looseGame', (room) => {
      let pls = '';
      let pls2 = '';
      room.players.forEach(player => {
        if (player.role === "Good") {
          pls = pls + player.username + ', ';
        } else {
          pls2 = pls2 + player.username + ', ';
        }
      });
      pls = pls.slice(0, -2);
      pls2 = pls2.slice(0, -2);
      let text = this.$t('timebomb.badWins') + '\n\n' + 'Good: ' + pls + '\n' + 'Bad: ' + pls2;
      this.createNotif(this.$t('timebomb.badWins'), text, 'good', '60000');
      // setPopin(text, 60000, 'green', false); // TODO

      let messageElement = document.createElement('div');
      messageElement.classList.add(COLOR_ORANGE);
      messageElement.innerHTML = this.$t('timebomb.badWins');
      messagesContainer.appendChild(messageElement);

      messageElement = document.createElement('div');
      messageElement.classList.add(COLOR_GREEN);
      messageElement.innerHTML = this.$t('timebomb.goodDoubleDot') + ' ' + pls;
      messagesContainer.appendChild(messageElement);

      messageElement = document.createElement('div');
      messageElement.classList.add(COLOR_RED);
      messageElement.innerHTML = this.$t('timebomb.badDoubleDot') + ' ' + pls2;
      messagesContainer.appendChild(messageElement);

      this.playSound("bomb.wav");
    });

    socket.on('left', (userId, username) => {
      const messageElement = document.createElement('div');
      messageElement.classList.add(COLOR_RED);
      messageElement.innerHTML = `<strong>${username}</strong>`+' left.';
      messagesContainer.appendChild(messageElement);
      this.createNotif(this.$t('timebomb.playerLeft'), username + ' ' + this.$t('timebomb.left'), 'info', '5000');
      this.playSound("exit.wav");
    });

    socket.on('dcGame', () => {
      const messageElement = document.createElement('div');
      messageElement.classList.add(COLOR_PURPLE);
      messageElement.innerHTML = this.$t('timebomb.oopsDc');
      messagesContainer.appendChild(messageElement);
    });
  },
  computed: {
    roomId() {
      return this.$store.state.code;
    },
    room() {
      return this.$store.state.room;
    },
    me() {
      return this.$store.getters.timebombMe;
    },
    firstGame() {
      return this.$store.state.firstGame;
    },
    totalAnnounced() {
      let count = 0;
      let players = this.$store.state.room.players;
      for (let i = 0; i<players.length; i++) {
        let player = players[i];
        if (player.announcedWires !== -1) {
          count += player.announcedWires;
        }
      }
      return count;
    }
  },
  inject: ['playSound', 'createNotif'],
  methods: {
    declareWires(nb) {
      if (this.$store.state.room.state !== 'started') return;
      if (this.$store.state.interfaceLocked) return;
      const socket = this.$store.state.socket;
      const roomId = this.roomId;
      socket.emit('announceWires', roomId, nb);
    },
    declareBomb(nb) {
      if (this.$store.state.room.state !== 'started') return;
      if (this.$store.state.interfaceLocked) return;

      const socket = this.$store.state.socket;
      const roomId = this.roomId;
      socket.emit('announceBomb', roomId, nb);
    },
    cutFil(username, nb) {
      if (!this.me.isCutter) return;
      if (this.me.username === username) return;
      if (this.$store.state.interfaceLocked) return;

      const socket = this.$store.state.socket;
      const roomId = this.roomId;
      socket.emit('cutFilServer', roomId, username, nb);
    },
    ucFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
};
</script>
