<template>

  <div class="page p-4">
    <div class="flex flex-col gap-4">
      <!-- No header -->
      <div class="flex flex-col gap-2">
        <router-link
            v-for="(menu, index,) in menus"
            :key="menu.key"
            :to="menu.href"
            :class="['flex items-center rounded-xl bg-gray-800 w-full overflow-hidden', { 'flex-row-reverse': index % 2 === 1 }]">
          <img :src="menu.img" class="max-w-[50%] max-h-[200px]" />
          <div class="grow p-2 flex flex-col justify-start gap-2">
            <h2 class="title" v-text="menu.title"></h2>
            <p class="text-xs p-1" v-text="menu.description"></p>
          </div>
        </router-link>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      menus: [
        {
          href: '/timebomb',
          key: 'timebomb',
          img: require('@/assets/timebomb_big.jpg'),
          title: 'Timebomb',
          description: 'Aiderez-vous Sherlock à désamorcer la bombe et sauvez Big Ben ? Ou assisterez-vous plutôt Moriarty dans son plan machiavélique ? Time Bomb est un jeu de bluff et de déduction où vous ressentez la tension monter tout au long de la partie !'
        },
        {
          href: '/timebomb',
          key: 'timebomb',
          img: require('@/assets/timebomb_big.jpg'),
          title: 'Timebomb',
          description: 'Aiderez-vous Sherlock à désamorcer la bombe et sauvez Big Ben ? Ou assisterez-vous plutôt Moriarty dans son plan machiavélique ? Time Bomb est un jeu de bluff et de déduction où vous ressentez la tension monter tout au long de la partie !'
        },
        {
          href: '/timebomb',
          key: 'timebomb',
          img: require('@/assets/timebomb_big.jpg'),
          title: 'Timebomb',
          description: 'Aiderez-vous Sherlock à désamorcer la bombe et sauvez Big Ben ? Ou assisterez-vous plutôt Moriarty dans son plan machiavélique ? Time Bomb est un jeu de bluff et de déduction où vous ressentez la tension monter tout au long de la partie !'
        },
        {
          href: '/timebomb',
          key: 'timebomb',
          img: require('@/assets/timebomb_big.jpg'),
          title: 'Timebomb',
          description: 'Aiderez-vous Sherlock à désamorcer la bombe et sauvez Big Ben ? Ou assisterez-vous plutôt Moriarty dans son plan machiavélique ? Time Bomb est un jeu de bluff et de déduction où vous ressentez la tension monter tout au long de la partie !'
        },
      ],
    }
  },
}
</script>
