<template>
  <div class="md:h-full md:min-h-screen md:w-48 min-w-48 w-full flex md:flex-col flex-wrap gap-4 text-sm text-white bg-gray-500 dark:bg-gray-900 p-2">
    <img src="../assets/gp.png" class="w-20 md:w-auto rounded"/>
    <div class="flex grow flex-row md:flex-col justify-end md:justify-start px-2 gap-1">
      <router-link v-for="menu in menuUpdated"
                   :to="menu.href"
                   :key="menu.title"
                   class="cursor-pointer md:grid md:grid-cols-3 flex justify-center items-center md:gap-4 p-1"
                   :class="{ 'menu-left-shadow font-bold': menu.active }">
        <div class="flex items-center justify-center">
          <img class="col-span-1 h-12" :src="menu.img" />
        </div>
        <p class="hidden md:block col-span-2 text-right">{{ menu.title }}</p>
      </router-link>
    </div>
    <p class="text-right text-sm px-2 hidden md:flex">By Good Loss</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        { href: '/', img: require('@/assets/home.svg'), key: 'home'},
        { href: '/timebomb', img: require('@/assets/bomb_mini.png'), key: 'timebomb'},
        { href: '/account', img: require('@/assets/people.svg'), key: 'account'},
        { href: '/settings', img: require('@/assets/settings.svg'), key: 'settings'},
        { href: '/changelogs', img: require('@/assets/credits.svg'), key: 'about'},
      ],
    }
  },
  computed: {
    menuUpdated() {
      return this.menus.map(menu => {
        if (menu.href === '/') {
          if (this.$route.path === '/') {
            return {
              ...menu,
              title: this.$t(`menu.${menu.key}`),
              active: true
            };
          } else {
            return {
              ...menu,
              title: this.$t(`menu.${menu.key}`),
              active: false
            };
          }
        }
        let normalizedPath = this.$route.path.endsWith('/') ? this.$route.path : this.$route.path + '/';
        let normalizedHref = menu.href.endsWith('/') ? menu.href : menu.href + '/';

        const active = normalizedPath.startsWith(normalizedHref);
        return {
          ...menu,
          title: this.$t(`menu.${menu.key}`),
          active: active
        };
      });
    },
  },
}
</script>
