<template>
  <div class="page p-4">
    <div v-if="!isAuth" class="flex flex-col gap-4">
      <div class="flex items-center gap-4">
        <img class="image-title" src="../assets/keys.svg" />
        <h1 class="title">{{ $t('account.login') }}</h1>
      </div>
      <form @submit.prevent="submitForm" class="w-fit my-auto max-w-full flex flex-col gap-2">
        <div class="w-full rounded-md flex flex-col gap-4 p-4">
          <input v-model="username" type="text" name="username" id="inputUsername" autocomplete="username" required="" autofocus=""
                 class="form-control btngray cursor-text w-full p-2 rounded-md focus:ring focus:ring-gray-500 focus:outline-none" placeholder="User Name">
          <input v-model="password" type="password" name="password" id="inputPassword" autocomplete="current-password" required=""
                 class="form-control btngray cursor-text w-full p-2 rounded-md focus:ring focus:ring-gray-500 focus:outline-none" placeholder="Password">
          <button type="submit" class="btngray w-full text-sm font-bold text-center text-white">{{ $t('account.login') }}</button>

          <a aria-label="steam"
             class="btngray w-full text-sm font-bold text-center text-white w-full flex items-center justify-center gap-2">
            <svg width="32px" height="32px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <path fill="white" d="M15.974 0c-8.401 0-15.292 6.479-15.943 14.714l8.573 3.547c0.729-0.495 1.604-0.786 2.552-0.786 0.083 0 0.167 0.005 0.25 0.005l3.813-5.521v-0.078c0-3.328 2.703-6.031 6.031-6.031s6.036 2.708 6.036 6.036c0 3.328-2.708 6.031-6.036 6.031h-0.135l-5.438 3.88c0 0.073 0.005 0.141 0.005 0.214 0 2.5-2.021 4.526-4.521 4.526-2.177 0-4.021-1.563-4.443-3.635l-6.135-2.542c1.901 6.719 8.063 11.641 15.391 11.641 8.833 0 15.995-7.161 15.995-16s-7.161-16-15.995-16zM10.052 24.281l-1.964-0.813c0.349 0.724 0.953 1.328 1.755 1.667 1.729 0.719 3.724-0.104 4.443-1.833 0.349-0.844 0.349-1.76 0.005-2.599-0.344-0.844-1-1.495-1.839-1.844-0.828-0.349-1.719-0.333-2.5-0.042l2.026 0.839c1.276 0.536 1.88 2 1.349 3.276s-2 1.88-3.276 1.349zM25.271 11.875c0-2.214-1.802-4.021-4.016-4.021-2.224 0-4.021 1.807-4.021 4.021 0 2.219 1.797 4.021 4.021 4.021 2.214 0 4.016-1.802 4.016-4.021zM18.245 11.87c0-1.672 1.349-3.021 3.016-3.021s3.026 1.349 3.026 3.021c0 1.667-1.359 3.021-3.026 3.021s-3.016-1.354-3.016-3.021z"></path>
            </svg>
            <span>Login with Steam</span>
          </a>
          <div class="flex justify-center">
            <p id="errors" class="hidden bg-red-700 rounded-md p-2 w-fit"></p>
          </div>
        </div>
        <div class="w-full rounded-md p-4 flex flex-col gap-2">
          <p class="w-full text-sm text-center">Don't have an account? <a aria-label="register" @click.prevent="openLink('https://goodloss.fr/register')" class="link">Register here</a></p>
          <p class="w-full text-sm text-center">Password forgotten ? <a aria-label="forgot password" @click.prevent="openLink('https://goodloss.fr/reset-password')" class="link">Reset it here !</a></p>
        </div>
      </form>
    </div>
    <div v-else class="flex flex-col gap-4">
      <div class="flex items-center gap-4">
        <img class="image-title" src="../assets/people.svg" />
        <h1 class="title">Account</h1>
      </div>
      <div class="flex text-lg flex-col gap-4">
        <div class="flex items-center gap-4">
          <p class="text-left w-20">User:</p>
          <p class="">{{ usernameFromToken }}</p>
        </div>
        <form @submit.prevent="logoutForm" class="flex items-center gap-4">
          <button type="submit" class="btngray w-auto text-sm font-bold text-center text-white">Logout</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
    };
  },
  inject: ['openLink'],
  computed: {
    isAuth() {
      return this.$store.getters.isAuthenticated;
    },
    usernameFromToken() {
      return this.$store.getters.loginFromToken;
    }
  },
  methods: {
    async submitForm() {
      const apiURL = 'https://goodloss.fr/api/user/login';
      try {
        const response = await axios.post(apiURL, {
          username: this.username,
          password: this.password,
        }, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

        // Gérer la réponse
        if (response.status === 200 && response.data) {
          this.$store.dispatch('login', response.data);
          this.$router.push('/');
        } else {
          document.getElementById('errors').innerText = "Wrong login or password";
          document.getElementById('errors').classList.remove('hidden');
        }
      } catch (error) {
        document.getElementById('errors').innerText = "Wrong login or password";
        document.getElementById('errors').classList.remove('hidden');
      }
    },
    async logoutForm() {
      this.$store.dispatch('logout');
      this.$router.push('/account');
    },
  }
}
</script>
