import { createStore } from 'vuex';
import { io } from "socket.io-client";
import router from '@/router'
import { settingsManager } from './SettingsManager';


export default createStore({
    state() {
        return {
            token: null,
            socket: null,
            code: null,
            room: null,
            firstGame: true,
            interfaceLocked: false,
            volume: 100,
            notifications: "On",
            lg: 'en',
        }
    },
    mutations: {
        setAuth(state, token) {
            state.token = token;
            settingsManager.set('token', token);
        },
        clearAuth(state) {
            state.token = null;
            settingsManager.set('token', null);
        },
        setSocket(state, payload) {
            state.socket = payload;
        },
        setCode(state, payload) {
            state.code = payload;
        },
        updateGame(state, payload) {
            state.room = payload;
        },
        setVolume(state, volume) {
            state.volume = volume;
        },
        setNotifications(state, notifications) {
            state.notifications = notifications;
        },
        setLg(state, lg) {
            state.lg = lg;
        },
    },
    actions: {
        login({ commit }, token) {
            commit('setAuth', token);
        },
        logout({ commit }) {
            commit('clearAuth');
        },
        initializeSocket({ commit, state }) {
            const socket = io('https://tbserv.goodloss.fr', {
                reconnection: true,  // Activer la reconnexion automatique
                reconnectionAttempts: 5,  // Nombre de tentatives de reconnexion
                reconnectionDelay: 1000,  // Délai initial entre les tentatives de reconnexion (en millisecondes)
                reconnectionDelayMax: 5000,  // Délai maximum entre les tentatives de reconnexion (en millisecondes)
                randomizationFactor: 0.5  // Facteur de randomisation pour le délai entre les tentatives de reconnexion (0.5 signifie une variation de 50%)
            });
            commit('setSocket', socket);

            socket.emit('loginToken', state.token);

            socket.on('tokenNotLogged', () => {
                router.push('/timebomb');
            });

            socket.on('disconnect', () => {
                console.log('La socket est déconnectée. Tentative de reconnexion...');
            });

            // Gestionnaire d'événement pour la reconnexion réussie
            socket.on('reconnect', (attemptNumber) => {
                console.log('Reconnexion réussie après ' + attemptNumber + ' tentatives.');
            });

            // Autres gestionnaires d'événements
            socket.on('event', (data) => {
                console.log('Événement reçu:', data);
            });
        },
        // disconnectSocket({ state }) {
        //     if (state.socket) {
        //         state.socket.disconnect();
        //         state.socket = null;
        //     }
        // },
        async loadSettings({ commit }) {
            const volume = await settingsManager.get('volume', 100);
            commit('setVolume', volume);

            const notifications = await settingsManager.get('notifications', "On");
            commit('setNotifications', notifications);

            const lg = await settingsManager.get('lg', 'en');
            commit('setLg', lg);

            const token = await settingsManager.get('token', null);
            commit('setAuth', token);
        },
        async saveVolume({ commit }, volume) {
            await settingsManager.set('volume', volume);
            commit('setVolume', volume);
        },
        async saveNotifications({ commit }, notifications) {
            await settingsManager.set('notifications', notifications);
            commit('setNotifications', notifications);
        },
        async saveLanguage({ commit }, lg) {
            await settingsManager.set('lg', lg);
            commit('setLg', lg);
        },
    },
    getters: {
        // Getter pour accéder à l'état d'authentification
        isAuthenticated: state => state.token != null,
        // Getter pour accéder au token
        token: state => state.token,
        loginFromToken: state => {
            if (state.token && state.token.includes('#')) {
                const parts = state.token.split('#');
                return parts[0];
            }
            return "";
        },
        timebombMe: state => {
            if (state.room && state.room.players && state.socket) {
                // Utiliser la méthode find pour trouver le joueur dont la socket correspond
                return state.room.players.find(player => player.socket == state.socket.id);
            }
            // Retourner null si aucun joueur correspondant ou si room/players/socket n'existe pas
            return null;
        },
        volume: state => {
            return state.volume;
        },
        lg: state => {
            return state.lg;
        },
        notifications: state => {
            return state.notifications;
        },
    }
});