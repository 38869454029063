import { createRouter, createWebHistory } from 'vue-router';
import TimeBomb from './components/TimeBomb.vue';
import ChangeLogs from './components/ChangeLogs.vue';
import AccountPage from './components/AccountPage.vue';
import SettingsPage from './components/SettingsPage.vue';
import TimeBombGame from './components/TimebombGame.vue';
import HomePage from './components/HomePage.vue';
import store from './store';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: HomePage },
        { path: '/timebomb', component: TimeBomb },
        { path: '/timebomb/game', component: TimeBombGame },
        { path: '/settings', component: SettingsPage },
        { path: '/changelogs', component: ChangeLogs },
        { path: '/account', component: AccountPage },
    ],
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters.isAuthenticated;

    if (!isAuthenticated && to.path !== '/account') {
        next('/account');
        return;
    }

    const hasCodeAndSocket = store.state.code != null && store.state.socket != null;

    if (!hasCodeAndSocket) {
        if (to.path === '/timebomb/game') {
            next('/timebomb');
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;