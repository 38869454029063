import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import { createI18n } from 'vue-i18n';

const messages = {
    en: {
        menu: {
            "timebomb": "Timebomb",
            "account": "Account",
            "settings": "Settings",
            "about": "About",
            "home": "Home"
        },
        settings: {
            'title': 'Settings',
            'volume': 'Volume',
            'language': 'Language',
            'notifications': 'Notifications',
        },
        account: {
            login: 'Login'
        },
        timebomb: {
            'join': 'Join room',
            'create': 'Create room',
            'roomCode': 'Room code',
            'codeTitle': 'Code:',
            'roomListTitle': 'Rooms list :',
            'game': 'Game',
            'yourTurn': 'Your turn',
            'announces': 'Announces',
            'startGame': 'Start Game',
            'gameInfoTitle': 'Game information:',
            'wiresRevealedTitle': 'Wires revealed:',
            'cardsTurnTitle': 'Cards revealed this turn:',
            'wiresTurnTitle': 'Wires announced this turn:',
            'playersTitle': 'Players:',
            'send': 'Send',
            'writeInChat': 'Write in tchat here...',
            'joined': 'joined.',
            'wireCut': 'Wire cut',
            'cutNeutralIn': 'cut a neutral wire in',
            'cutWireIn': 'cut a wire in',
            'madeExplode': 'made the bomb explode in',
            'inHand': '\'s hand',
            'neutral': 'neutral',
            'wire': 'wire',
            'bomb': 'bomb',
            'turnFinished': 'Turn finished. Please, wait 5 seconds until next round.',
            'newTurn': 'New turn',
            'newTurnDot': 'New turn.',
            'canCut': 'can cut.',
            'goodWins': 'Good wins',
            'badWins': 'Bad wins',
            'good': 'Good:',
            'bad': 'Bad:',
            'playerLeft': 'Player left',
            'left': 'left.',
            'oopsDc': 'Oops! Someone got disconnected during game! Therefore, the game ended.',
            'playerJoined': 'Player joined',
            'gameStarted': 'Game started',
        },
        about: {
            'title': 'About',
            'content': 'Game Platform has been made by Matux & Good Loss.\nYou\'re using version 0.1.\nIt contains these games:\nTimebomb.',
        }
    },
    fr: {
        menu: {
            "timebomb": "Timebomb",
            "account": "Compte",
            "settings": "Paramètres",
            "about": "A propos",
            "home": "Accueil"
        },
        settings: {
            'title': 'Paramètres',
            'volume': 'Volume',
            'language': 'Langage',
            'notifications': 'Notifications',
        },
        account: {
            login: 'Connexion'
        },
        timebomb: {
            'join': 'Rejoindre la partie',
            'create': 'Créer une partie',
            'roomCode': 'Code de la partie',
            'codeTitle': 'Code :',
            'roomListTitle': 'Liste des parties :',
            'game': 'Partie',
            'yourTurn': 'A ton tour',
            'announces': 'Annonces',
            'startGame': 'Démarrer la partie',
            'gameInfoTitle': 'Informations :',
            'wiresRevealedTitle': 'Fils révélés :',
            'cardsTurnTitle': 'Cartes révélées ce tour :',
            'wiresTurnTitle': 'Fils révélés ce tour :',
            'playersTitle': 'Joueurs :',
            'send': 'Envoyer',
            'writeInChat': 'Ecrit dans le tchat ici...',
            'joined': 'a rejoint.',
            'wireCut': 'Fil coupé',
            'cutNeutralIn': 'a coupé un fil neutral dans la main de',
            'cutWireIn': 'a coupé un fil dans la main de',
            'madeExplode': 'a fait exploser la bombe dans la main de',
            'inHand': '',
            'neutral': 'neutre',
            'wire': 'fil',
            'bomb': 'bombe',
            'turnFinished': 'Tour terminé. Patiente 5 secondes avant le prochain tour.',
            'newTurn': 'Nouveau tour',
            'newTurnDot': 'Nouveau tour.',
            'canCut': 'peut couper.',
            'goodWins': 'Les gentils ont gagné',
            'badWins': 'Les méchants ont gagné',
            'good': 'Gentils',
            'bad': 'Méchants',
            'goodDoubleDot': 'Gentils:',
            'badDoubleDot': 'Méchants:',
            'playerLeft': 'Un joueur est parti',
            'left': 'est parti.',
            'oopsDc': 'Oups! Quelqu\'un s\'est déconnecté pendant la partie! Par conséquent, la partie est terminée.',
            'playerJoined': 'Un joueur est arrivé',
            'gameStarted': 'La partie a démarré',
        },
        about: {
            'title': 'A propos',
            'content': 'Game Platform a été créé par Matux & Good Loss.\nTu utilises la version 0.1.\nCes jeux sont disponibles :\nTimebomb.',
        }
    }
};

store.dispatch('loadSettings').then(() => {

    const i18n = createI18n({
        legacy: false, // vous devez définir ceci pour Vue 3
        locale: store.state.lg,
        fallbackLocale: 'en', // définissez la locale de secours
        messages, // assignez les messages à l'option de configuration
    });

    createApp(App)
        .use(router)
        .use(store)
        .use(i18n)
        .mount('#app')
});
