<template>
  <div id="themeDiv" class="dark">
    <div class="bg-white text-left dark:bg-gray-700 text-black dark:text-white text-sm flex flex-col md:flex-row items-center justify-center h-full w-full min-h-screen">
      <MenuLeft />
      <div class="min-h-screen max-h-screen h-full flex grow p-2 overflow-auto">
        <div class="w-full">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuLeft from './components/MenuLeft.vue'
import './compiled.css';
import './notifications.css';
import './notifications';
import { mapState } from 'vuex';

export default {
  name: 'App',
  components: {
    MenuLeft
  },
  computed: {
    ...mapState(['language']),
  },
  watch: {
    '$route' () {
    },
    language(newLang) {
      this.$i18n.locale = newLang;
    },
  },
  methods: {
    createNotif(title, msg, type, duration) {
      if (this.$store.getters.notifications !== "On") return;

      const myNotification = window.createNotification({
        closeOnClick: true,
        displayCloseButton: false,
        positionClass: 'nfc-bottom-left',
        onclick: false,
        showDuration: duration,
        theme: type,
      });
      myNotification({
        title: title,
        message: msg,
      });
    },
    playSound(link) {
      let sound = new Audio("/sounds/"+link);
      sound.volume = this.$store.getters.volume / 100;
      sound.play();
    },
    openLink(url) {
      if (window.electronAPI && window.electronAPI.openExternal) {
        window.electronAPI.openExternal(url);
      } else {
        window.open(url, '_blank').focus();
      }
    },

  },
  provide() {
    return {
      playSound: this.playSound,
      createNotif: this.createNotif,
      openLink: this.openLink,
    };
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
