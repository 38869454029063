<template>

  <div class="page p-4">
    <div class="flex flex-col gap-4">
      <div class="flex items-center gap-4">
        <img class="image-title" src="../assets/bomb_mini.png" />
        <h1 class="title">Timebomb</h1>
      </div>

      <div class="flex justify-between gap-4">
        <div id="gameBoards" class="grow rounded-md bg-gray-800 p-2">
          <h2 class="title2" id="gamesListTitle">{{ $t('timebomb.roomListTitle') }}</h2>
          <div class="grid grid-cols-1 md:grid-cols-4 gap-2">
            <div v-for="room in rooms" :key="room.code" class="h-auto col-span-1 md:col-span-4 flex items-center justify-between gap-2 bg-gray-700 p-2 rounded-md">
              <div>{{ room.code }}</div>
              <div>{{ room.players[0].username }}</div>
              <div>{{ room.players.length }}/12</div>
              <div>{{ room.state === 'created' ? 'Waiting' : 'Started' }}</div>
              <button v-if="room.state === 'created'" @click="joinRoom(room.code)" class="rounded-md p-2 bg-blue-700">{{ $t('timebomb.join') }}</button>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <form @submit.prevent="submitJoin" class="flex flex-col gap-4 bg-gray-800 rounded-md p-2">
            <h2 class="title2">{{ $t('timebomb.join') }}</h2>
            <div class="flex justify-between items-center gap-2">
              <p>{{ $t('timebomb.codeTitle') }}</p>
              <input id="joinCode" type="text" :placeholder="$t('timebomb.roomCode')" class="col-span-6 text-black rounded-md p-2" />
            </div>
            <button type="submit" class="rounded-md p-2 bg-blue-700">{{ $t('timebomb.join') }}</button>
          </form>
          <form @submit.prevent="submitCreate" class="flex flex-col gap-4 bg-gray-800 rounded-md p-2">
            <h2 class="title2">{{ $t('timebomb.create') }}</h2>
            <div class="flex justify-between items-center gap-2">
              <p>{{ $t('timebomb.codeTitle') }}</p>
              <input id="createCode" type="text" :placeholder="$t('timebomb.roomCode')" class="col-span-6 text-black rounded-md p-2" value="" />
            </div>
            <button type="submit" class="rounded-md p-2 bg-blue-700">{{ $t('timebomb.create') }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      rooms: [], // Stocke les données des salles
    };
  },
  mounted() {
    if (this.$store.state.code) {
      this.$router.push('/timebomb/game');
      return;
    }
    this.$store.dispatch('initializeSocket').then(() => {
      const socket = this.$store.state.socket;

      socket.emit('getRooms');

      socket.on('showRooms', (roomsData) => {
        this.rooms = roomsData;
      });
    });
  },
  methods: {
    async submitCreate() {
      let codeValue = document.getElementById('createCode').value;
      this.joinRoom(codeValue);
    },
    async submitJoin() {
      let codeValue = document.getElementById('joinCode').value;
      this.joinRoom(codeValue);
    },
    joinRoom(code) {
      if (code.length === 6) {
        const socket = this.$store.state.socket;
        this.$store.commit('setCode', code);

        socket.emit('joinRoom', code, this.$store.getters.token);
        socket.on('enteredGame', () => {
          this.$router.push('/timebomb/game');
        });
      } else {
        alert("The code must be exactly 6 characters!");
      }
    }
  }
};
</script>
