<template>
  <div class="page p-4">
    <div class="flex flex-col gap-4">
      <!-- En-tête des paramètres -->
      <div class="flex items-center gap-4">
        <img class="image-title" src="../assets/settings.svg" />
        <h1 class="title">{{ $t('settings.title') }}</h1>
      </div>

      <div class="flex items-center text-lg">
        <label class="min-w-[200px]" for="language-selector">{{ $t('settings.volume') }}</label>
        <div class="col-span-4 flex items-center gap-2">
          <input type="range" id="volumeControl" min="0" max="100" :value="volume" step="10" class="w-full" @input="setVolume">
          <img id="soundButton" class="cursor-pointer h-5 w-5" src="../assets/sound.svg">
        </div>
      </div>

      <!-- Langue -->
      <div class="flex items-center text-lg">
        <label class="min-w-[200px]" for="languageSelector">{{ $t('settings.language') }}</label>
        <select id="languageSelector" class="selectbox px-4" @change="setLg">
          <option :selected="isSelected('en')" value="en">English</option>
          <option :selected="isSelected('fr')" value="fr">Français</option>
        </select>
      </div>

      <!-- Notifications -->
      <div class="flex items-center text-lg">
        <label class="min-w-[200px]">{{ $t('settings.notifications') }}</label>
        <button id="notifSelector" @click="toggleNotifications" class="bg-gray-300 dark:bg-gray-800 p-2 rounded-md text-lg px-4">
          {{ notifications }}
        </button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  methods: {
    setVolume() {
      const volumeControlDiv = document.getElementById('volumeControl');
      const newVolumeValue = volumeControlDiv.value;
      this.$store.dispatch('saveVolume', newVolumeValue);
    },
    async setLg() {
      const lgControlDiv = document.getElementById('languageSelector');
      const newLgValue = lgControlDiv.value;
      await this.$store.dispatch('saveLanguage', newLgValue);
      this.$i18n.locale = this.$store.getters.lg;
    },
    isSelected(lg) {
      return this.$store.getters.lg === lg;
    },
    toggleNotifications() {
      const notifControlDiv = document.getElementById('notifSelector');
      let newNotifValue = notifControlDiv.innerText;
      console.log(newNotifValue)
      newNotifValue = newNotifValue === "On" ? "Off" : "On";
      console.log(newNotifValue)
      this.$store.dispatch('saveNotifications', newNotifValue);
    }
  },
  computed: {
    volume() {
      return this.$store.getters.volume;
    },
    notifications() {
      return this.$store.getters.notifications;
    },
  }
}
</script>